import React from "react"
import Layout from '../components/layout'
import LocationHelmet from "../components/LocationHelmet"
import PageTitle from "../components/PageTitle"
import { themeStyles, colors, presets, metrics } from '../utils/theme'

const PestControl = () => {
    return (
    <Layout>
      <LocationHelmet
        title={"Pest Control in Pleasant Grove | Blumont Services"}
        description={"Discover effective organic pest control solutions for businesses and commercial properties in Spanish Fork with Blumont Services, your trusted pest control partner."}
        keywords={"Pest Control Pleasant Grove, Organic pest control for businesses, Pest control for commercial properties, spanish fork pest control, pest control spanish fork"}
        url={"https://www.blumontservices.com/pest-control"}
      />
      <PageTitle fontSize={2} title={"Pest Control in Pleasant Grove "}/>
      <div css={[themeStyles.contentWidth]}>
        <div css={themeStyles.textPadding}>
          <p>
            Discover effective organic pest control solutions for businesses and commercial properties in Spanish Fork with Blumont Services, your trusted pest control partner.
          </p>
          <div style={{ marginBottom: '25vh' }}></div>
        </div>
      </div>
    </Layout>
    )
}

export default PestControl;